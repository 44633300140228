import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const PortfolioLayoutWrapper = styled.div`
  position: relative;
  max-width: 100%;
  padding: 1rem 3rem;

  .website-url {
    text-decoration: none;
    color: #484848;

    &:hover,
    &:focus {
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#41aa0a),
        to(#0797b9)
      );
      background: linear-gradient(to right, #41aa0a, #0797b9);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      text-shadow: none;
    }
  }
`

const FeaturedImage = styled.img`
  max-width: 100%;
  margin: 16px 0;
`

const PortfolioTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  color: #171717;
  font-size: 45px;
  font-weight: 300;
`

const Portfolio = ({ pageContext }) => {
  return (
    <Layout>
      <PortfolioLayoutWrapper>
        <PortfolioTitle>{pageContext.title}</PortfolioTitle>
        <strong>Website Url: </strong>
        <a
          className="website-url"
          href={pageContext.acf.portfolio_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {pageContext.acf.portfolio_url}
        </a>
        <FeaturedImage src={pageContext.featured_media.source_url} />
        <div dangerouslySetInnerHTML={{ __html: pageContext.content }} />
      </PortfolioLayoutWrapper>
    </Layout>
  )
}

export default Portfolio
